















































import {Component, Vue} from 'vue-property-decorator';
import ILoginForm from '@/types/ILoginForm';
import ITerm from '@/types/ITerms';
import {email, required} from '@/utils/validationRules';
import AuthService from '@/services/AuthService';
import UserService from '@/services/UserService';
import {resolveError} from '@/utils/notifications';
import store from '@/store';
import {namespace} from 'vuex-class';
import {ILanguage} from '@/types/TranslationTypes';

const Translations = namespace('translations');

@Component({name: 'LoginForm'})
export default class LoginForm extends Vue {

  @Translations.Getter
  getCurrentLanguage!: string;

  @Translations.Getter
  languagesList!: ILanguage[];

  form: ILoginForm = {
    username: '',
    password: '',
    clientId: null,
  };
  rules: object = {
    username: [required, email],
    password: [required],
  };
  loading: boolean = false;

  // @user.Mutation
  // setUser!: (user: IUserInfo) => void

  async submit() {
    // @ts-ignore
    if (this.$refs.form.validate()) {
      this.loading = true;
      try {
        await AuthService.login(this.form);
        // await this.setUser(user)
        this.$router.push({name: 'Home'}).catch(e => e);
        await this.checkTermsToAccept();
      } catch (e) {
        console.log('error', e)
        resolveError(e, 'login');
      } finally {
        this.loading = false;
      }
    }
  }

  async checkTermsToAccept() {
    const language: ILanguage = this.languagesList
        .find((element) => element.shortcut === this.getCurrentLanguage) || this.languagesList[0];
    try {
      const termsToAccept: ITerm[] = await UserService.getTermsToAccept(language);
      if (termsToAccept.length > 0) {
        store.commit('user/setTermsToAccept', termsToAccept);
      }
    } catch (e) {
      resolveError(e, 'getTerms');
    }
  }
}
